<template>
    <div v-if="getItem('showMainChatWindow')" class="chat-box">
        <Loading :active="getItem('loading')"
            :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
            :can-cancel="false"
            :lock-scroll="true"
            backgroundColor="white"
            :opacity="0.9"
            :z-index=999
        />
        <div class="chat-toolbar">
            <div class="chat-toolbar-left-wrapper">
                <div class="ai-chat-icon"><img src="../../img/ai-chat-icon.svg" /></div>
                <div class="previous-chats" :class="isActiveHistory ? 'active':''" @click.prevent="handleShowPreviousChats">
                    <img src="../../img/ai-chat-history-active.svg" v-if="isActiveHistory"/>
                    <img src="../../img/ai-chat-history-inactive-icon.svg" v-else/>
                    History
                </div>
                <div class="new-chat" @click.prevent="newChat ? handleNewChat() : handleLoadPreviousChat(chat)" :class="isActiveChat ? 'active':''" >
                    <img src="../../img/ai-chat-active-icon.svg" v-if="isActiveChat"/>
                    <img src="../../img/ai-chat-inactive-icon.svg" v-else/>
                    Chat
                </div>
            </div>
            <div class="close-chat" @click.prevent="handleCloseChat"><img src="../../img/ai-chat-close-icon.svg" /></div>
        </div>
        <div v-if="getItem('showChatMessageWindow')" class="chat-window">
            <div class="chat-body" @scroll="handleChatScroll">
                <div v-if="getItem('loadingMoreMsgs')" class="chat-message-wrapper assistant">
                    <div class="loader loadmoremsgs">
                        <span></span><span></span><span></span>
                    </div>
                </div>
                <div v-if="!getItem('loading') && (getItem('chatMessages').length==0 || (getItem('chatMessages').length>0 && !getItem('chatMessages')[0].id)) " class="chat-message-wrapper assistant">
                    <div class="chat-message">Hi! I'm I2I Assistant, a digital agent that can give instant answers to your questions.</div>
                </div>
                    <template v-for="(message, index) in (clickedToChatFirstTime ? chatMessage : getItem('chatMessages'))">
                    <div  :key="index" :class="['chat-message-wrapper', message.role]">
                        <div v-if="message.role=='system'" class="chat-message"> {{ message.content }}</div>
                        <div v-if="message.role=='user'" class="chat-message"> {{ message.content }}<img src="../../img/ai-chat-copy-to-clipboard.svg" @click.prevent="copyToClipboard(message.content)"/></div>
                        <div v-if="message.role=='assistant'" class="chat-message" >
                        <div v-html="getMessageWithCitation(message).content"></div>
                        <hr>
                        <div class="message-assistant-wrapper">
                            <div class="message-assistant-rating">
                                Was this helpful?
                                <img v-if="message.rating !== 1" @click.prevent="handleChatMessageRating(message.id, message.rating, 1)" src="../../img/thumbs-up.svg"/>
                                <img v-else @click.prevent="handleChatMessageRating(message.id, message.rating, '')" src="../../img/thumbsup-fill.svg"/>
                                <img v-if="message.rating !== 0" @click.prevent="handleChatMessageRating(message.id, message.rating, 0)" src="../../img/thumbs-down.svg"/>
                                <img v-else @click.prevent="handleChatMessageRating(message.id, message.rating, '')" src="../../img/thumbsdown-fill.svg"/>
                            </div>
                            <div class="message-assistant-copy"><img src="../../img/ai-chat-copy-to-clipboard.svg" @click.prevent="copyToClipboard(message.content)"/></div>
                        </div>
                        </div>
                    </div>
                </template>
                <div v-if="getItem('waitingReply')" class="chat-message-wrapper assistant">
                    <div class="loader">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>

            <div class="chat-message-box-container" :class="getItem('chatMessages').length >= 2 ? 'overflow-chat':''">
                <div class="chat-message-box">
                    <div class="chat-message-txt">
                        <SaiTextArea :disabled="getItem('waitingReply')" placeholder="Ask i2i Assistant..." name="userMessage" id="usermessage" :value="getItem('userMessage')" @input="$event => setItem({field: 'userMessage', value: $event})" autocomplete="off" @keydown.enter.exact.prevent="handleSendMessage"/>
                    </div>
                    <div class="chat-send-button-wrapper">
                        <div class="chat-send-button"  @click.prevent="handleSendMessage">
                            <img src="../../img/ai-chat-send-icon.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="getItem('showPreviousChatList')" class="previous-chat-list" @scroll="handleHistoryScroll">
            <div class="btn-start-new-conversation" @click.prevent="handleNewChat"><img class="img-new-chat" src="../../img/ai-chat-btn-new-chat-icon.svg"/>&nbsp;&nbsp;New Chat</div>
            <div class="chat-history-title">Chat History</div>
            <div v-if="getPreviousItemChatList().length==0">
                No previous chat. Click New chat to start new conversation.
            </div>
            <div v-for="(chat, index) in getPreviousItemChatList()" :key="index" class="chat-list-item">
                <div class="chat-history-icon"><img src="../../img/ai-chat-history-icon.svg"/></div>
                <div class="chat-history-title" @click.prevent="handleLoadPreviousChat(chat)"> {{ chat.title || chat.newTitle }}</div>
                <div class="delete-previous-chat" title="Delete this chat history" @click.prevent="handleDeleteChatHistory(chat.id)"><img src="../../img/ai-chat-history-delete.svg" /></div>
            </div>
            <div v-if="getItem('loadingMoreChats')" class="">
                <div class="loader loadmorechats">
                    <span></span><span></span><span></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import SaiTextArea from '@/components/common/SaiTextArea';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "index",
        props: {
            docId: { type: String,  default: '' },
            itemId: { type: String, default: '' },
            userId: { type: String,  default: '' },
            chatId: { type: String, default: '' }
        },
        components: {
            SaiTextArea,
            Loading
        },
        data() {
            return {
                prevChatScrollTop: 0,
                prevHistoryScrollTop: 0,
                isActiveHistory: false,
                isActiveChat: true,
                chat: null,
                chatMessage: [],
                clickedToChatFirstTime: false,
                newChat: false,
            }
        },
        computed: {
            ...aiChatMapGetters([
                'getItem',
                'getPreviousChatList'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'sendMessageToAI',
                'loadPreviousChatList',
                'loadPreviousChat',
                'loadNewChat',
                'closeChat',
                'showPreviousChatList',
                'deleteChatHistory',
                'rateChatMessage',
                'loadMoreMessages',
                'loadMoreChats'
            ]),
            handleLoadPreviousChat(chatReference) {
                this.chat = chatReference;
                this.newChat = false;
                this.isActiveChat = true;
                this.isActiveHistory = false;
                if(this.chat != null){
                    this.clickedToChatFirstTime = false;
                    this.loadPreviousChat(this.chat);
                }else{
                    this.setItem({field: 'showChatMessageWindow', value: true});
                    this.setItem({field: 'showPreviousChatList', value: false});
                    this.chatMessage = this.getItem('chatMessages');
                    this.clickedToChatFirstTime = true;
                }
            },
            handleCloseChat() {
                this.isActiveChat = true;
                this.isActiveHistory = false;
                this.newChat = false;
                this.closeChat();
            },
            handleShowPreviousChats() {
                this.isActiveHistory = true;
                this.isActiveChat = false;
                this.showPreviousChatList();
            },
            handleNewChat() {
                this.isActiveChat = true;
                this.isActiveHistory = false;
                this.newChat = true;
                this.loadNewChat({ docId: this.getItem('docId'), itemId: this.getItem('itemId'), userId: this.getItem('userId'), resId: this.getItem('resId'), pubId: this.getItem('pubId'), subId: this.getItem('subId')});
            },
            handleSendMessage() {
                let message = this.getItem('userMessage');
                if(message.trim()!=='') {
                    this.setItem({field: 'userMessage', value: ''});
                    this.sendMessageToAI(message);
                }
            },
            getPreviousItemChatList() {
                return this.getPreviousChatList({id: this.getItem('itemId'), type: 'item'});
            },
            handleDeleteChatHistory(chatId) {
                this.deleteChatHistory(chatId);
                return false;
            },
            getMessageWithCitation: function(msg) {
                let message = {...msg}; //create new object so that the when message content is maniupulated below,
                                        //it doesnot trigger the rerender, making it stuck in infinite loop
                if(message.citations && message.citations.length) {
                    const docId = this.getItem('docId');
                    const itemId = this.getItem('itemId');
                    const resId = this.getItem('resId');
                    const pubId = this.getItem('pubId');
                    let citations = [];
                    let messageContent = message.content;
                    messageContent.replace(/\[doc(\d)\]/g, function(match)  {
                        citations.push(match.slice(1, -1));
                        return match;
                    });
                    let citationSeqNo = 1;
                    let citationLinks = "";
                    citations = citations.sort();
                    let prevCitation = "";

                    citations.forEach(function(item) {
                        if (item != prevCitation) {
                            var replace = "\\["+item+"\\]";
                            var regex = new RegExp(replace,"g");
                            messageContent = messageContent.replace(regex, '<sup class="citation">' + citationSeqNo +'</sup>');
                            if(message.citations.length) {
                                let citationIndex = parseInt(item.replace('doc',''))-1;
                                let page = 0;
                                let decodedString = atob(message.citations[citationIndex].content);
                                let citationArray = JSON.parse(decodedString);


                                if(message.citations[citationIndex] && message.citations[citationIndex]['metadata']) {
                                    page = message.citations[citationIndex]['metadata']['page'];
                                } else if (message.citations[citationIndex] && message.citations[citationIndex]['title'] && message.citations[citationIndex]['title']['page']) {
                                    page = message.citations[citationIndex]['title']['page'];
                                }

                                page = page + 1;

                                if(citationArray.length > 0) {
                                    let firstNonEmptyIndex = citationArray.findIndex(item => item.trim() !== "");
                                    if(firstNonEmptyIndex !== -1) {
                                        let firstFiveWords = citationArray[firstNonEmptyIndex].split(' ').slice(0, 5).join(' ');
                                        citationLinks += ''+citationSeqNo+'&nbsp&nbsp<a target="_blank" href="/management/display/citations/' + docId + '/' + itemId + '/' + resId + '/' + pubId + '/' + page + '">Page '+page+', '+ firstFiveWords +'...' +'</a></br>';
                                    } else {
                                        citationLinks += ''+citationSeqNo+'&nbsp&nbsp<a target="_blank" href="/management/display/citations/' + docId + '/' + itemId + '/' + resId + '/' + pubId + '/' + page + '">Page '+page+', ...'+'</a></br>';
                                    }
                                }
                            }
                            citationSeqNo++;
                            prevCitation=item;
                        }
                    });
                    if(citationLinks) {
                        messageContent = messageContent + "</br></br></br>References:-<br>" + citationLinks;
                    }
                    // Match consecutive <sup> tags
                    const pattern = /(<sup class="citation">\d+<\/sup>)+/g;
                    // Replace them with a <div class="sup"> wrapper
                    messageContent = messageContent.replace(pattern, function(match) {
                        return '<div class="sup-citation">' + match + '</div>';
                    });
                    message.content = messageContent;
                }
                return message;
            },
            handleChatMessageRating(messageId, currentRating, rating) {
                if(currentRating === rating) {
                    rating = '';
                }
                this.rateChatMessage({messageId, rating});
            },
            async copyToClipboard(content) {
                try {
                    await navigator.clipboard.writeText(content);
                } catch (error) {
                    console.error('Unable to copy to clipboard:', error);
                }
            },

            handleHistoryScroll: function(el) {
                if(this.prevHistoryScrollTop == 0) {
                    this.prevHistoryScrollTop = el.srcElement.scrollTop;
                }
                let previousChatList = this.getPreviousItemChatList();
                if((el.srcElement.scrollHeight - (el.srcElement.scrollTop +  el.srcElement.clientHeight)) < 10) {
                    if(this.prevHistoryScrollTop  < el.srcElement.scrollTop  && !this.getItem('loadingMoreChats')  && !this.getItem('noChatHistroy')) {
                        if(previousChatList.length >0 ) {
                            let lastChat = previousChatList[previousChatList.length-1];
                            this.loadMoreChats({ docId: this.getItem('docId'), itemId: this.getItem('itemId'), userId: this.getItem('userId'), resId: this.getItem('resId'), pubId: this.getItem('pubId'), subId: this.getItem('subId'), lastChatId: lastChat.id});
                        }
                    }

                }
                this.prevHistoryScrollTop = el.srcElement.scrollTop;
            },

            handleChatScroll: function(el) {
                if(this.prevChatScrollTop == 0) {
                    this.prevChatScrollTop = el.srcElement.scrollTop;
                }
                if(el.srcElement.scrollTop < 20) {
                    if(this.prevChatScrollTop >  el.srcElement.scrollTop && !this.getItem('loadingMoreMsgs')) {
                        if(this.getItem('chatMessages').length>0 && (this.getItem('chatMessages')[0].id && this.getItem('chatMessages')[0].role !='system')) {
                            let lastChatItem = this.getItem('chatMessages')[0];
                            this.loadMoreMessages({chatId: this.getItem('chatId'), lastMessageId: lastChatItem.id});
                        }
                    }
                }
                this.prevChatScrollTop = el.srcElement.scrollTop;
            }
        }
    }
</script>

<style scoped lang="scss">
@import "aichat";
</style>