<template>
    <div v-if="getItem('showCompareWindow')" class="pdf-compare-box">
        <Loading :active="getItem('loading')"
            :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
            :can-cancel="false"
            :lock-scroll="true"
            backgroundColor="white"
            :opacity="0.9"
            :z-index=999
        />
        <div class="compare-box-header">
            <img src="../../img/ai-icon.svg"/>
             Assistant
            <img @click.prevent="handleClose" class="close-icon" src="../../img/icon-close.svg"/></div>
        <div class="compare-box-container">
            <div :class="['compare-box']">
                <div :class="['compare-body', getItem('loading') ? 'no-visible': '', getItem('showError') ? 'with-error':'']">
                    <div class="compare-header" :class="getItem('showError')?'hide':''">
                        <div :class="['compare-header-title', showTOCSelectoin ? '': 'hide']">Choose sections, click "Compare," and review highlighted differences. </div>
                        <div class="compare-header-spacer">&nbsp;</div>
                    </div>
                    <div :class="['section-selection', showTOCSelectoin ? '': 'hide', getItem('showError')?'hide':'']">
                        <div class="toc-wrapper">
                            <div class="toc-title-left">{{ getTOCArtefactName(1) }}</div>
                            <div class="toc-left">
                                <TOC v-for="section in getTOC(1)" :key="section.id" :node="section" :selected-item="getItem('toc1Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 0)"></TOC>
                            </div>
                        </div>
                        <div class="toc-wrapper">
                            <div class="toc-title-right">{{  getTOCArtefactName(2) }}</div>
                            <div class="toc-right">
                                <TOC v-for="section in getTOC(2)" :key="section.id" :node="section" :selected-item="getItem('toc2Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 1)"></TOC>
                            </div>
                        </div>

                    </div>
                    <VueShowdown :class="['compare-summary', showCompareSummary ? '': 'hide']" :markdown="this.getItem('diffSummary')" flavor="github" :options="{ emoji: true }"/>
                    <div :class="['compare-error', getItem('showError') ? '': 'hide']" v-html="getItem('errMessage')"></div>
                </div>
                <div class="compare-header-prompt-selection" :class="[getItem('showError') ? 'hide' : '', showRatingOption ? 'results' : '']">


                    <div :class="['compare-rating', showRatingOption ? '': 'hide']">
                        Was this helpful? &nbsp;
                        <img
                            v-if="getItem('selectedRating') == '1'"
                            @click="handleSelectRating('-1')"
                            class="thumbsup"
                            src="../../img/thumbsup-fill.svg"
                        />
                        <img
                            v-else
                            @click="handleSelectRating('1')"
                            class="thumbsup"
                            src="../../img/thumbs-up.svg"
                        />
                        <img
                            v-if="getItem('selectedRating') == '0'"
                            @click="handleSelectRating('-1')"
                            class="thumbsdown"
                            src="../../img/thumbsdown-fill.svg"
                        />
                        <img
                            v-else
                            @click="handleSelectRating('0')"
                            class="thumbsdown"
                            src="../../img/thumbs-down.svg"
                        />
                    </div>



                    <div class="result-format-text" v-if="showCopyButton">
                        <div class="select-format-wrapper">
                                <img class="icon-regenerate" @click.prevent="handleCompare" src="../../img/icon-regenerate.svg" />
                            <SaiSelect
                            :value ="getItem('selectedPromptId')"
                            class="compare-prompt"
                            name="compare_prompt"
                            labelBy="id"
                            trackBy="title"
                            :options="getItem('promptOptions')"
                            placeholder="Select prompt..."
                            @change="handleSelectPrompt"/>
                        </div>
                        <div class="copy-clipboard" @click.prevent="handleCopyToClipBoard" :class="[showCopyButton ? '': 'hide']">
                            <img src="../../img/icon-copy.svg" />
                        </div>
                    </div>
                    <div class="result-format-text" :class="[getItem('loading') ? 'hide': '']" v-else>
                        Choose results format:
                        <SaiSelect
                        :value ="getItem('selectedPromptId')"
                        class="compare-prompt"
                        name="compare_prompt"
                        labelBy="id"
                        trackBy="title"
                        :options="getItem('promptOptions')"
                        placeholder="Select prompt..."
                        @change="handleSelectPrompt"/>
                    </div>
                </div>
                <div class="compare-cta">
                    <div class="btn-spacer">&nbsp;</div>
                    <div v-if="!getItem('loading')" :class="['btn', 'go-back',showBackButton ? '': 'hide', getItem('showError') ? 'hide':'']"  :style="{'z-index': 1000}" @click.prevent="handleBack">Go Back</div>
                    <div v-if="!showCopyButton" class="btn" @click.prevent="handleClose" :style="{ 'z-index': 1000}">Back</div>
                    <div :class="['btn', 'btn-compare', activateCompareButton ? 'active': '',  showCompareButton ? '': 'hide', getItem('showError') ? 'hide':'']" @click.prevent="handleCompare">
                        <img class="icon-compare" src="../../img/icon-btn-compare.svg"/>Compare</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SaiSelect from '@/components/common/SaiSelect';
    import Loading from 'vue-loading-overlay';
    import TOC from './TOC'
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "index",
        components: {
            Loading,
            TOC,
            SaiSelect
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showTOCSelectoin',
                'showCompareSummary',
                'showCopyButton',
                'showBackButton',
                'showCompareButton',
                'activateCompareButton',
                'showRatingOption',
                'showRegenerateSummary'
            ]),
            compareReady: function() {
                return (typeof this.getItem('toc1Selection').id !== "undefined") && (typeof this.getItem('toc2Selection').id !== "undefined");
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'closeCompare',
                'getSecitonDiffSummary',
                'updateSummaryRating'
            ]),
            handleCloseCompare() {
                this.closeCompare();
            },
            getTOC: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['toc'];
                }
                return [];
            },
            getTOCArtefactName: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['artefact_name'];
                }
                return '';
            },
            handleTitleClick(tocItem, tocIndex) {
                if(tocIndex == 0) {
                    this.setItem({field: 'toc1Selection', value: tocItem});
                }
                if(tocIndex == 1) {
                    this.setItem({field: 'toc2Selection', value: tocItem});
                }
            },
            abortApiCall() {
                let checkDiffStatusApi = this.getItem('abortController.checkDiffStatusApi');
                if(checkDiffStatusApi) {
                    checkDiffStatusApi.abort();
                }
                let compareApi = this.getItem('abortController.compareApi');
                if(compareApi) {
                    compareApi.abort();
                }
                let loadTOCApi = this.getItem('abortController.loadTOCApi');
                if(loadTOCApi) {
                    loadTOCApi.abort();
                }
            },
            handleBack() {
                this.setItem({field: 'loading', value: false})
                this.setItem({field: 'diffSummaryId', value: 0})
                this.setItem({field: 'selectedRating', value: -1})
                this.setItem({field: 'showTOCSelection', value: true})
                this.setItem({field: 'showCompareSummary', value: false})
                this.setItem({field: 'showError', value: false})
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleClose() {
                this.setItem({field: 'loading', value: false})
                this.setItem({field: 'diffSummaryId', value: 0})
                this.setItem({field: 'selectedRating', value: -1})
                this.setItem({field: 'toc1Selection', value: {}})
                this.setItem({field: 'toc2Selection', value: {}})
                this.setItem({field: 'showCompareWindow', value: false});
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleCompare() {
                if(this.compareReady) {
                    this.setItem({field: 'processAborted', value: false});
                    let compareContent = []
                    compareContent.push({
                        itemId: this.getItem('toc1').uid,
                        startPage: this.getItem('toc1Selection').startPage,
                        endPage: this.getItem('toc1Selection').endPage,
                        title: this.getItem('toc1Selection').title,
                        itemName: this.getItem('itemName1')
                    });
                    compareContent.push({
                        itemId: this.getItem('toc2').uid,
                        startPage: this.getItem('toc2Selection').startPage,
                        endPage: this.getItem('toc2Selection').endPage,
                        title: this.getItem('toc2Selection').title,
                        itemName: this.getItem('itemName2')
                    });
                    const selectedPromptId = this.getItem('selectedPromptId');
                    const docId = this.getItem('docId');
                    const actionedBy = this.getItem('userId');
                    const docName = this.getItem('docName');
                    const language = this.getItem('language');
                    this.getSecitonDiffSummary({ compareContent, selectedPromptId, docId, actionedBy, docName, language });
                    this.setItem({field: 'selectedRating', value: -1})
                }
            },
            handleSelectPrompt(event) {
                this.setItem({field: 'selectedPromptId', value: event});
            },
            handleSelectRating(rating) {
                const currentRating = this.getItem('selectedRating');
                if (currentRating === rating) {
                    this.setItem({ field: 'selectedRating', value: '-1' });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                } else {
                    this.setItem({ field: 'selectedRating', value: rating });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                }
            },

            handleCopyToClipBoard() {
                var copyText = this.getItem('diffSummary');
                navigator.clipboard.writeText(copyText);
            }
        }
    }
</script>

<style lang="scss">
@import "aicompare";
</style>