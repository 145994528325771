<template>
    <div v-if="getItem('faqActive')" class="faq-box">
      <div class="faq-toolbar">
        <Loading :active="getItem('loading')"
            :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
            :can-cancel="false"
            :lock-scroll="true"
            backgroundColor="white"
            :opacity="0.9"
            :z-index=999
        />
        <div class="faq-toolbar-left-wrapper">
          <div class="ai-faq-icon"><img src="../../img/ai-chat-icon.svg" /></div>
        </div>
        <div class="faq-heading">Frequently Asked Questions</div>
        <div @click.prevent="closeFaqWindow" class="close-faq"><img src="../../img/ai-chat-close-icon.svg" /></div>
      </div>
      <div class="faq-window">
        <div class="faq-body">
          <div class="faq-hint">
            <p class="hint-title">Top Five Frequently Asked Question</p>
            <p class="hint-description">This information is only available for standards of select publishers only.</p>
          </div>
          <div class="faq-container">
            <div class="faq-accordion-container" v-for="(faq, index) in getItem('faqData')" :key="index">
              <div class="faq-accordion" :class="{ 'accordion-expanded': expandedAccordions[index] }" @click.prevent="toggleAccordion(index)">
                <span>{{ faq.question }}</span>
                <img v-if="expandedAccordions[index]" src="../../img/faq-accordion-up.svg"/>
                <img v-else src="../../img/faq-accordion-down.svg"/>
              </div>
              <div class="accordion-answer" :class="{ 'accordion-expanded': expandedAccordions[index] }" v-if="expandedAccordions[index]">
                {{ faq.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { createNamespacedHelpers } from 'vuex';
  import Loading from 'vue-loading-overlay';
  const { mapActions: aiFaqMapActions, mapGetters: aiFaqMapGetters } = createNamespacedHelpers('aifaq');

  export default {
    name: "index",
    props: {
      docId: { type: String, default: '' }
    },
    components: {
      Loading
    },
    data() {
      return {
        expandedAccordions: []
      };
    },
    computed: {
      ...aiFaqMapGetters([
        'getItem'
      ])
    },
    watch: {
      getItem() {
        this.initializeAccordions();
      }
    },
    mounted() {
      this.initializeAccordions();
      console.log('mounted2');
    },
    methods: {
      ...aiFaqMapActions([
        'setItem',
        'initFaq',
        'closeFaq',
      ]),
      initializeAccordions() {
        this.expandedAccordions = new Array(this.getItem('faqData').length).fill(false);
      },
      toggleAccordion(index) {
        this.$set(this.expandedAccordions, index, !this.expandedAccordions[index]);
      },
      closeFaqWindow() {
        this.closeFaq();
      },
    }
  };
  </script>

  <style scoped lang="scss">
  @import "faq";
  </style>
